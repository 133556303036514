<script>
  import { Keyboard } from "@capacitor/keyboard";
  import {
      Block,
      Button,
      f7,
      Page,
      PageContent,
      Sheet
  } from "framework7-svelte";
  import { onMount } from "svelte";
  import NotificationComponent from "../components/notifications.svelte";
  import { Event, recordEvent } from "../js/analytics.ts";
  import { device, dispatch, EVENT } from "../js/app.ts";
  import { translate as tr, translateWithUsername as trU } from "../js/i18n.ts";
  import {
      getStoredNotificationTimes,
      setupNotifications
  } from "../js/notifications.ts";
  import {
      navigateSession,
      ROUTE_INTRODUCTION,
      setShowIntroPage
  } from "../js/routes.ts";
  import { getUser, setUser } from "../js/settings.ts";

  export let f7router;

  let sheetOpened = false;

  // variable bound to UI
  const userRef = getUser(); //-> { value: User  }
  const isNotificationsSupported = !device.web;

  // user form submit
  function onUsernameSubmit(e) {
    setUserName();
    e.preventDefault(); // prevent the enter/submit to do anything further
    next();
    Keyboard.hide();
  }

  function setUserName() {
    const formData = f7.form.convertToData("#intro-user-form");
    const username = formData.username ? formData.username : ""; // user did not provide name
    const userObj = { name: username };
    // update state
    userRef.value = userObj;
    // store username in app
    setUser(userObj);
  }

  function getSwiper() {
    return f7.swiper.get(".swiper-intro");
  }

  function next() {
    setUserName(); // save user name in settings
    getSwiper().slideNext(); // go to next page
  }

  function startCoherence() {
    setShowIntroPage(false); // no more intro instead of /home
    navigateSession(f7router, 2, { props: { intro: true } }); // show intro session
  }

  function saveNotificationsAndStart(checkEmpty) {
    if (!isNotificationsSupported) {
      startCoherence();
      return;
    }

    // save notifications now
    dispatch(EVENT.notificationSave);

    // check user has at least one notifications
    if (checkEmpty && getStoredNotificationTimes().length == 0) {
      sheetOpened = true; // open confirmation notifications are
      return;
    }

    // setup notifications and ask permissions
    setupNotifications(f7).finally(() => {
      startCoherence();
    });
  }

  // listen to swipe events
  onMount(() => {
    const swiper = getSwiper();

    if (swiper) {
      // can be unset for web intro single page
      // record slide change as page views
      swiper.on("slideChange", function () {
        // make an index like /introduction/1,2,3
        recordEvent(Event.PageView, {
          page_location: `${ROUTE_INTRODUCTION}/${swiper.activeIndex + 1}`
        });
      });

      window.setTimeout(() => {
        if (swiper.activeIndex === 0) {
          // if user did not already move page
          const inpt = f7.$('input[name="username"]');
          inpt.focus();
          inpt[0].select(); // select all text
        }
      }, 1500); // invite to type name
    }
    // record first slide view
    recordEvent(Event.PageView, { page_location: `${ROUTE_INTRODUCTION}/1` });
  });
</script>

<Page name="intro" class="page-intro no-default-bg">
  <!-- on PWA show a single page intro -->
  <swiper-container pagination class="swiper-intro" space-between="20">
    <swiper-slide>
      <div class="card card-raised card-full-height">
        <div class="card-header sunset-bg"></div>
        <div class="card-content card-content-padding">
          <div class="title">{tr("intro.page_1_title")}</div>
          <div class="subtitle">{@html tr("intro.page_1_subtitle")}</div>
          <div>
            <form
              id="intro-user-form"
              class="list"
              on:submit={onUsernameSubmit}>
              <ul>
                <li class="item-content item-input item-input-outline">
                  <div class="item-inner">
                    <div class="item-input-wrap">
                      <input
                        type="text"
                        placeholder={tr("intro.page_1_msg_1")}
                        name="username"
                        value={userRef.name} />
                    </div>
                  </div>
                </li>
              </ul>
            </form>
            <div>{tr("intro.page_1_msg_2")}</div>
            <ul>
              <li>{tr("intro.page_1_privacy_2")}</li>
              <li>{tr("intro.page_1_privacy_3")}</li>
              <li>{tr("intro.page_1_privacy_4")}</li>
              <li>{tr("intro.page_1_privacy_5")}</li>
            </ul>
          </div>
        </div>
        <div class="bottom-button">
          <a href={"#"} class="button button-fill color-blue" on:click={next}
            >{tr("intro.page_1_button")}</a>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="card card-full-height">
        <div class="card-header hrv-bg"></div>
        <div class="card-content card-content-padding">
          <div class="title">{tr("intro.page_2_title")}</div>
          <div class="subtitle">{@html tr("intro.page_2_subtitle")}</div>
          <p>{@html trU("intro.page_2_msg_1")}</p>
          <ul class="iconlist">
            <li class="iconitem">
              <span class="iconutf">🧘‍♂️ </span>{tr("intro.page_2_benefit_1")}
            </li>
            <li class="iconitem">
              <span class="iconutf">🌙 </span>{tr("intro.page_2_benefit_2")}
            </li>
            <li class="iconitem">
              <span class="iconutf">🧠 </span>{tr("intro.page_2_benefit_3")}
            </li>
            <li class="iconitem">
              <span class="iconutf">💪 </span>{tr("intro.page_2_benefit_4")}
            </li>
          </ul>
          <p>{tr("intro.page_2_msg_2")}</p>
        </div>
        <div class="bottom-button">
          <a href={"#"} class="button button-fill color-blue" on:click={next}
            >{tr("common.next")}</a>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="card card-full-height">
        <div class="card-header sunset-bg card-notifications"></div>
        <div class="card-content card-content-padding">
          <div class="title padding-top">{tr("intro.page_3_title")}</div>
          <div class="subtitle">{@html tr("intro.page_3_subtitle")}</div>
          {#if isNotificationsSupported}
            <p>{@html tr("intro.page_3_msg_1")}</p>
            <div class="intro-notifications">
              <NotificationComponent scheduleOnSave={false} />
            </div>
          {/if}
          <p>
            {@html tr("intro.page_3_msg_2")}
          </p>
        </div>
        <div class="bottom-button">
          <a
            href={"#"}
            class="button button-fill color-green"
            on:click={() => saveNotificationsAndStart(true)}>
            {tr("intro.page_3_allset_button")}
          </a>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>

  <Sheet
    class="demo-sheet"
    style="height: auto"
    swipeToClose
    opened={sheetOpened}
    onSheetClosed={() => (sheetOpened = false)}>
    <PageContent>
      <Block>
        <div class="text-align-center top-results">
          <h1>{tr("common.are_you_sure")}</h1>
          <p>
            {tr("intro.page_3_no_reminder_confirmation")}
          </p>
        </div>
      </Block>

      <Block>
        <Button fill color="purple" raised sheetClose
          >{tr("intro.page_3_set_reminders")}</Button>
        <Button sheetClose onClick={() => saveNotificationsAndStart(false)}
          >{tr("common.no_thanks")}</Button>
      </Block>
    </PageContent>
  </Sheet>
</Page>

<style>
  :root {
    --swiper-pagination-bottom: 4dvh;
    --swiper-pagination-bottom: 4vh; /* fallback when no dvh support */
    --swiper-pagination-bullet-size: 12px;
    --swiper-pagination-color: purple;
  }

  .card-header {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 42%;
  }

  ul.iconlist {
    list-style-type: none;
    padding-inline-start: 16px;
  }

  ul li.iconitem {
    margin: 8px;
  }

  @media (max-height: 700px) {
    ul li.iconitem {
      margin: 0px;
    }
    span.iconutf {
      font-size: 1em;
    }
  }

  span.iconutf {
    font-size: 1.4em;
  }

  /* smaller image on small screens */
  @media (max-height: 700px) {
    .card-header.card-notifications {
      height: 32%;
    }
  }

  @media (max-height: 512px) {
    .card-header {
      height: 25%;
    }

    .card-header.card-notifications {
      height: 20%;
    }
  }

  .card-full-height {
    height: 94dvh;
    height: 94vh; /* fallback when no dvh support */
  }

  .bottom-button {
    position: absolute;
    bottom: 6dvh;
    bottom: 6vh; /* fallback when no dvh support */
    margin: 4px 16px;
    width: calc(100% - 32px);
    z-index: 42;
  }

  /* smaller padding on intro view for small devices */
  :global(.intro-notifications .calendar-time-selector a) {
    padding: 0 6px;
  }

  .card-header.sunset-bg {
    background-image: url(/images/intro-bg.svg);
  }

  .card-header.hrv-bg {
    background-image: url(/images/intro-heart.svg);
  }

  .subtitle {
    color: var(--f7-block-title-text-color);
    text-align: center;
  }

  .title {
    font-size: 20px;
    text-align: center;
  }

  :global(.title .padding-top) {
    padding-top: var(--f7-card-content-padding-vertical);
  }

  #intro-user-form li {
    margin: 0;
    margin-top: 16px;
  }

  :global(.page-intro) {
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' %3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23000000'/%3E%3Cstop offset='1' stop-color='%23050008'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpattern id='b' width='24' height='24' patternUnits='userSpaceOnUse'%3E%3Ccircle fill='%23ffffff' cx='12' cy='12' r='12'/%3E%3C/pattern%3E%3Crect width='100%25' height='100%25' fill='url(%23a)'/%3E%3Crect width='100%25' height='100%25' fill='url(%23b)' fill-opacity='0.1'/%3E%3C/svg%3E");
    background-attachment: fixed;
  }
</style>

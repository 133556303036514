<script>
  import {
      Block,
      f7ready,
      Link,
      List,
      ListItem,
      Navbar,
      NavRight,
      Page,
      Popover,
      Stepper
  } from "framework7-svelte";
  import SunriseBackground from "../components/sunrise-background.svelte";
  import { browseURL, showInstallToast } from "../js/app.ts";
  import tr, { getWelcomeMessage, splitTranslation } from "../js/i18n.ts";
  import { WEBSITE_INFO_URL } from "../js/media.ts";
  import {
      navigateSession,
      ROUTE_SESSION_SETTINGS,
      ROUTE_SETTINGS,
      ROUTE_STATS
  } from "../js/routes.ts";
  import { getStats, getTotalDaysIncludingToday } from "../js/session.ts";
  import {
      APP_NAME,
      getSettings,
      getStoredValue,
      setStoredValue,
      StoreValue
  } from "../js/settings.ts";

  // Router component will receive f7router prop with current Router instance
  export let f7router;

  const stats = getStats();
  const totalDays = getTotalDaysIncludingToday(stats);
  
  var lastSelectedTime = getStoredValue(StoreValue.favorite_time, 5); // default to 5m

  // get welcome mnessage
  const welcomeTitle = getWelcomeMessage(new Date().getHours());
  const [i_have_n_mins, n_mins_to] = splitTranslation(
    "home.have_time_to",
    "minutes"
  );

  function isSoundEnabled() {
    const settings = getSettings();
    return settings.background_music_enabled || settings.sound_effects_enabled;
  }

  function start() {
    startCustomTimeSession(lastSelectedTime);
  }

  function startCustomTimeSession(minutes, props) {
    if (minutes <= 0 || minutes > 600)
      throw Error(`Invalid minutes range ${minutes}`);

    console.log(`home: starting coherence ${minutes}m`);
    navigateSession(f7router, minutes, props);
  }

  function updateCustomTimeSession(minutes) {
    if (minutes <= 0 || minutes > 600)
      throw Error(`Invalid minutes range ${minutes}`);
    setStoredValue(StoreValue.favorite_time, minutes);
    lastSelectedTime = minutes;
  }

  const formatMinutes = (min) =>
    `${min} ${tr("common.minute", { count: min }).toLowerCase()}`;

  const browseSupportPage = () =>
    browseURL(
      "https://coherence-app.com/support-us?utm_source=app&utm_content=home_heart_link"
    );

  const browseHelpPage = () =>
    browseURL(
      "https://coherence-app.com/information/tag/help?utm_source=app&utm_content=home_help_link"
    );

  let installTimer;
  let installToast;
  let soundEnabled = isSoundEnabled();

  const onPageAfterIn = () => {
    f7ready((f7) => {
      // offer user to install if needed
      installTimer = setTimeout(() => {
        installToast = showInstallToast(f7);
      }, 3000);

      // refresh sound enabled status
      soundEnabled = isSoundEnabled();
    });
  };

  function onPageBeforeOut() {
    // should close toast as well
    if (installTimer) {
      clearTimeout(installTimer);
      installTimer = null;
    }
    if (installToast) {
      installToast.close();
      installToast = null;
    }
  }
</script>

<Page
  pageContent={false}
  name="home"
  class="no-default-bg"
  onPageAfterIn={onPageAfterIn}
  onPageBeforeOut={onPageBeforeOut}>
  <Navbar transparent>
    <NavRight>
      <Link
        style="padding: 0 5px;"
        iconMaterial="spa"
        href={ROUTE_STATS}
        title={tr("settings.statistics")}>
        {totalDays}</Link>
      <Link
        style="padding: 0 5px;"
        iconMaterial="settings"
        title={tr("settings.title")}
        class="settings-button"
        href={ROUTE_SETTINGS}></Link>
    </NavRight>
  </Navbar>

  <SunriseBackground />

  <div class="container">
    <div class="messages-container">
      <Block textColor="white" style="text-align: center;">
        <h1 style="margin: 0;" title="Cardiac Coherence">
          <Link
            onClick={() => browseURL(WEBSITE_INFO_URL)}
            style="color: white;">{APP_NAME}</Link>
        </h1>
        <h2 class="slogan">
          <Link
            onClick={() => browseURL(WEBSITE_INFO_URL)}
            style="color: white;">
            {tr("about.slogan")}</Link>
        </h2>
      </Block>
      <Block textColor="white" style="text-align: center; margin-top: 10vh">
        <div class="settings-container">
          <div class="left-content">
            <div class="settings-time">
              <Link
                popoverOpen=".popover-menu"
                iconMaterial={"schedule"}
                color="white"
                iconSize="24px"
                title={tr("home.click_duration")}>
                <span class="time-msg">{tr("common.time")}</span>
                <span class="time-popup"
                  >{formatMinutes(lastSelectedTime)} ▼</span>
              </Link>
            </div>
          </div>
          <div class="right-content">
            <Link
              color="white"
              title={`${tr("settings.media")} ${tr("settings.title")}`}
              href={ROUTE_SESSION_SETTINGS}>
              <i class="icon material-icons"
                >{soundEnabled ? "volume_up" : "volume_off"}</i
              ></Link>
          </div>
        </div>
        <button class="button button-home" on:click={start}
          >{tr("common.start").toUpperCase()} ▶</button>
      </Block>
    </div>
    <!-- messages-container -->
  </div>
  <!-- container -->

  <Popover
    class="popover-menu"
    backdrop={false}
    closeByOutsideClick={true}
    closeOnEscape={true}
    verticalPosition="auto"
    style="width: 16.5em; opacity 0.9;">
    <List>
      <ListItem>
        <Stepper
          slot="media"
          min={1}
          max={600}
          step={1}
          value={lastSelectedTime}
          small
          onStepperChange={updateCustomTimeSession} />
        <span slot="after">{tr("common.minute", { count: lastSelectedTime })}</span>
      </ListItem>
      {#each [5, 10, 15, 20, 25, 30] as timeMin}
        <ListItem
          link={"#"}
          popoverClose
          title={formatMinutes(timeMin)}
          on:click={() => updateCustomTimeSession(timeMin)} />
      {/each}
    </List>
  </Popover>
</Page>

<style>
  :root {
    --f7-block-margin-vertical: 16px;
    --f7-block-inset-side-margin: 0px;
  }

  h1 {
    font-size: clamp(2em, 4vw, 3em);
    line-height: 1.1;
    font-optical-sizing: auto;
    font-weight: bold;
    text-shadow: 1px 1px 2px white;
  }

  h2 {
    font-size: clamp(1.5em, 2vw, 2.5em);
    font-optical-sizing: auto;
    font-weight: bold;
  }

  h2.slogan {
    margin: 0;
    font-size: 1em;
    font-style: italic;
  }

  .container {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  .messages-container {
    position: fixed;
    top: 10%;
    width: 100%;
    z-index: 42;
  }

  .settings-container {
    width: clamp(20em, 62vw, 660px);
    margin-left: auto;
    margin-right: auto;
    font-size: clamp(1.06em, 2vw, 1.4em);
    font-optical-sizing: auto;
    font-weight: bold;
    display: grid;
    grid-template-columns: 5fr 1fr;
    max-width: 500px;
  }

  .time-popup {
    text-decoration-line: underline;
  }

  .left-content {
    align-self: center;
    justify-self: start; /* Align to the start of the cell */
    padding: 10px;
  }

  .right-content {
    align-self: center;
    justify-self: end; /* Align to the end of the cell */
    padding: 10px;
    text-align: right;
  }

  @media (min-width: 768px) {
    .messages-container {
      width: 70vw;
      margin-left: 16vw;
    }
  }

  .button {
    --background-color: #644dff;
    --border-color: #4836bb;
    --shadow-color: #654dff63;
    cursor: pointer;
    width: clamp(16em, 4vw, 20em);
    height: clamp(3em, 4vh, 5em);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: clamp(1.3em, 4vw, 2.2em);
    font-weight: 800;
    letter-spacing: 2px;
    color: #fff;
    background: var(--background-color);
    border: 2px solid var(--border-color);
    border-radius: 0.75rem;
    box-shadow: 0 8px 0 var(--border-color);
    transform: skew(-10deg);
    transition: all 0.1s ease;
    filter: drop-shadow(0 15px 20px var(--shadow-color));
    margin-left: auto;
    margin-right: auto;
  }

  .button:active {
    letter-spacing: 0px;
    transform: skew(-10deg) translateY(8px);
    box-shadow: 0 0 0 var(--shadow-color);
  }
</style>

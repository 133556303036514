<script lang="ts">
  import { Block, Icon, Link, Page, Popup, Toolbar } from "framework7-svelte";
  import { getTimerLikeDurationText } from "../js/date";
  import { translate as tr } from "../js/i18n";
  import {
      getMinutesPracticedOnDay,
      getPracticedWeekDays,
      getStats
  } from "../js/session";
  import Ribbon from "./ribbon.svelte";
  import WeekBadge from "./week-badge.svelte";

  export let completedTimeSecs;
  export let onContinuePressed: null | (() => void) = null;
  export let onFinishPressed: null | (() => void) = null;
  export let onDiscardPressed: null | (() => void) = null;

  let popup: Popup;
  const stats = getStats();
  const todayDate = new Date();
  const practicedWeekDays = getPracticedWeekDays(stats, todayDate);

  // reactive statements
  $: minDoneNow = Math.round(
    completedTimeSecs > 0 ? completedTimeSecs / 60 : 0
  ); // check completedTimeSecs is positive
  $: minPracticedTotal = stats.totalMins + minDoneNow;
  $: timerText = getTimerLikeDurationText(completedTimeSecs * 1000);

  const minToday = getMinutesPracticedOnDay(stats, todayDate);
  $: minPracticedToday = minToday + minDoneNow;
  // add today if not already practiced today
  const totalDays = minToday > 0 ? stats.totalDays + 1 : stats.totalDays;

  export const open = () => {
    popup.instance().open();
  };

  export const close = () => {
    popup.instance().close();
  };

  const closeRunner = (func: (() => void) | null): void => {
    if (func) {
      // use settimeout here or a weird bug happens where the popup
      // is not removed from the DOM
      setTimeout(func, 0);
    }
    close();
  };

  const onDiscard = (): void => {
    closeRunner(onDiscardPressed); // call client function if any
  };

  const onFinish = () => {
    closeRunner(onFinishPressed); // call client function if any
  };

  const onContinue = () => {
    closeRunner(onContinuePressed); // call client function if any
  };

  // make sure it's closed
  // onDestroy(() => close());
</script>

<Popup class="finish-page" bind:this={popup} backdrop={false}>
  <Page>
    <Block>
      <div class="text-align-center top-results">
        <Icon material="emoji_events" size="48px" color="yellow"></Icon>
        <Ribbon text={totalDays} />
        <span class="text-total-days"
          >{`${tr("stats.total")} ${tr("common.day", { count: totalDays })}`}</span>
        <h2>{tr("common.you_completed")}</h2>
        <h1>{timerText}</h1>
      </div>
    </Block>

    <Block>
      <div class="text-align-center">
        <WeekBadge checkedDays={practicedWeekDays}></WeekBadge>
      </div>
    </Block>

    <Block>
      <div class="grid grid-cols-2 grid-gap">
        <div class="text-align-center">
          <span class="text-large">{minPracticedToday}</span>
          <br />
          <span class="text-small"
            >{`${tr("common.minute", { count: minPracticedToday })} ${tr("common.today")}`}</span>
        </div>

        <div class="text-align-center">
          <span class="text-large">{minPracticedTotal}</span>
          <br />
          <span class="text-small"
            >{`${tr("common.minute", { count: minPracticedTotal })} ${tr("stats.total")}`}</span>
        </div>
      </div>
    </Block>

    <Block>
      <p style="margin-top: 2em; font-size: 1.1em;">
        <span style="color: var(--f7-block-title-text-color);"
          >{@html tr("stats.continue_pep_talk")}</span>
      </p>
    </Block>

    <Toolbar tabbar icons position="bottom">
      <Link
        class="button-discard"
        tabLink="#tab-discard"
        tabLinkActive
        iconMaterial="delete"
        color="grey"
        onClick={onDiscard}
        text={tr("common.discard")} />
      <Link
        class="button-finish"
        tabLink="#tab-finish"
        tabLinkActive
        iconMaterial="stop"
        color="orange"
        onClick={onFinish}
        text={tr("common.finish")} />
      <Link
        class="button-continue"
        tabLink="#tab-continue"
        tabLinkActive
        iconMaterial="play_arrow"
        color="green"
        onClick={onContinue}
        text={tr("common.continue")} />
    </Toolbar>
  </Page>
</Popup>

<style>
  .text-total-days {
    font-size: 1em;
    font-weight: bold;
  }

  .top-results {
    padding-top: 5vh; /* fallback */
    padding-top: 5dvh;
  }
  @media (max-height: 512px) {
    .top-results {
      padding-top: 8px;
    }
  }

  .text-large {
    font-size: 2em;
    font-weight: bold;
  }

  /* TODO remove global */
  :global(.md .tabbar i.icon::before, .md .tabbar-icons i.icon::before) {
    border-radius: 28px;
    height: 46px;
    width: 92px;
  }

  :global(.md .tabbar-label) {
    --f7-tabbar-label-font-size: 14px;
    line-height: 2;
  }
</style>

import { differenceInCalendarDays, startOfWeek } from "date-fns";
import { logError } from './app.ts';
import { getDayName, getWeekDaysNames } from "./date.ts";
import { NoSleep } from './nosleep.js';
import type { SessionStats } from './settings.js';
import { getSessionStats, getSetting, SETTINGS } from './settings.js';

const noSleep = new NoSleep();

export const preventScreenSleep = async (block: boolean) => {
    try {
        if (block) {
            await noSleep.enable(); // async block
            console.debug(`screensleep: blocking screen enabled=${noSleep.enabled}`);
        }
        else {
            await noSleep.disable();
            console.debug('screensleep: allowing screen sleep');
        }
        // TODO: on error, should re-enable using visibility listener ?

    } catch (err: any) { // eslint-disable-line  @typescript-eslint/no-explicit-any
        logError(`screensleep: Error doing block=${block}`, err);
    }
}

export const getStatsSessionNumber = () => {
    return getSessionStats().length;
}

interface Stats {
    totalMins: number,
    totalBreaths: number,
    totalDays: number,
    currentStreak: number,
    bestStreak: number,
    /** Sorted in ascending order */
    sessions: SessionStats[],
    /** grouped by year/month/day */
    dateGroups: Record<string, SessionStats[]>
}

/**
 * @returns compute statistics based on currently recorded history
 */
export const getStats = (): Stats => {
    return computeStats(getSessionStats());
}

/**
 * @returns the number of minutes practiced on a specific date
 */
export const getMinutesPracticedOnDay = (stats: Stats, date: Date): number => {
    const sessions = getSessionsForDate(stats, date); // today
    return Math.round(sessions.reduce((partialSum, sess) => partialSum + (sess.elapsed / 60), 0));
}

/**
 * @returns the number of days practiced plus one if there was a session done today
 */
export const getTotalDaysIncludingToday = (stats: Stats): number => {
    const minPracticedToday = getMinutesPracticedOnDay(stats, new Date());
    // add today if not already practiced today
    return minPracticedToday > 0 ? stats.totalDays + 1 : stats.totalDays;
}

/**
 * @returns a list of stored session stats for a specific date
 */
export const getSessionsForDate = (stats: Stats, date: Date) => {
    return getSessionsForDay(stats, date.getFullYear(), date.getMonth(), date.getDate());
};

/**
 * @returns a list of stored session stats for a specific date
 */
export const getSessionsForMonth = (stats: Stats, month: Date) => {
    const daySessStats = stats.dateGroups[`${month.getFullYear()}-${month.getMonth() + 1}`];
    return daySessStats ? daySessStats : [];
};


/**
 * @returns a list of stored session stats for a specific day
 */
export const getSessionsForDay = (stats: Stats, year: number, month: number, day: number): SessionStats[] => {
    const daySessStats = stats.dateGroups[`${year}-${month + 1}-${day}`];
    return daySessStats ? daySessStats : [];
};

/**
 * Get map of {"day name": boolean} of each practiced day of the week in the current language
 * @param stats the statistics object to use
 * @param currentDay the current day to check for
 * @param currentDayPracticed default practiced state to set for the current day
 * @returns a map of {"day name": boolean} of in the current language
 */
export const getPracticedWeekDays = (stats: Stats, currentDay: Date, currentDayPracticed = true): Map<string, boolean> => {
    const daysOfWeekMap = new Map<string, boolean>();
    const startWeekDay = startOfWeek(currentDay);
    const weekDaysNames = getWeekDaysNames();
    // default to all days not practiced
    for (const nDay of weekDaysNames) {
        daysOfWeekMap.set(nDay, false);
    }
    // check each day prior until the first day of the week for practice
    for (const weekDay = new Date(currentDay); weekDay >= startWeekDay; weekDay.setDate(weekDay.getDate() - 1)) {
        let hasPracticed = false;
        // set current day status
        if (weekDay.getTime() == currentDay.getTime()) {
            hasPracticed = currentDayPracticed;
        } else {
            hasPracticed = getMinutesPracticedOnDay(stats, weekDay) > 0;
        }
        daysOfWeekMap.set(getDayName(weekDay), hasPracticed);
    }
    return daysOfWeekMap;
};

/**
 * Compute general practice statistics basd on the history
 * 
 * @param sessions the recorded ssession stats
 * @returns a statistics object with relevant information
 */
export const computeStats = (sessions: SessionStats[]): Stats => {
    let lastDate: Date | null = null;
    sessions = [...sessions].sort((a, b) => a.date - b.date); // copy, sort by date

    const stats: Stats = {
        totalMins: 0, totalBreaths: 0, totalDays: 0, currentStreak: 0,
        bestStreak: 0, sessions: sessions, dateGroups: {}
    };
    const breathTime = getSetting(SETTINGS.breathing_time) as number || 5;
    const groups = stats.dateGroups;
    const daySet = new Set();

    for (const sess of sessions) {
        const seconds = sess.elapsed
        const minutes = Math.round(seconds / 60); // in seconds

        stats.totalMins += minutes
        stats.totalBreaths += Math.round(seconds / (breathTime * 2)) // TODO use settings breath time

        if (!sess.date) continue; // no date
        const sessDate = new Date(sess.date);

        // compute streaks
        if (lastDate !== null) {
            const daysDiff = differenceInCalendarDays(sessDate, lastDate);
            if (daysDiff > 1) {
                stats.currentStreak = 1; // not in streak anymore, start new streak
            } else if (daysDiff === 1) { // same day
                stats.currentStreak++; // another day in streak
                stats.bestStreak = Math.max(stats.bestStreak, stats.currentStreak);
            }
        } else { // no last date (first date)
            stats.currentStreak++;
            stats.bestStreak++;
        }
        lastDate = sessDate;

        // group by day/month/year
        const addToGroup = (key: string, se: SessionStats) => {
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(se);
        };
        const year = sessDate.getFullYear();
        const month = sessDate.getMonth();
        const day = sessDate.getDate();

        const dayStr = `${year}-${month + 1}-${day}`;
        daySet.add(dayStr);

        addToGroup(dayStr, sess)
        addToGroup(`${year}-${month + 1}`, sess)
        addToGroup(`${year}`, sess)
    }

    // count total days
    stats.totalDays = daySet.size;

    return stats;
}



<script>
  import { Link, Toolbar } from "framework7-svelte";
  import { browseURL, getAppInfo } from "../../js/app.ts";
  import { translate as tr } from "../../js/i18n.ts";
  import { FEEDBACK_URL } from "../../js/media.ts";

  export let f7router;
  var clickCount = 0;
  const showDebugPage = () => {
    clickCount++;
    if (clickCount % 5 == 0) {
      // every 5 clicks
      f7router.navigate("/debug");
    }
  };
</script>

<div class="page" data-name="about">
  <div class="navbar">
    <div class="navbar-inner sliding">
      <div class="left">
        <a href={"#"} class="link back">
          <i class="icon icon-back"></i>
          <span class="if-not-md">Back</span>
        </a>
      </div>
      <div class="title">{tr("settings.about")}</div>
    </div>
  </div>
  <div class="page-content">
    <div class="block">
      <p>{@html tr("about.free_descr")}</p>
    </div>
    <div class="list">
      <ul>
        <li>
          <a
            href={"#"}
            class="item-link item-content"
            on:click={() =>
              browseURL(
                "https://coherence-app.com/support-us?utm_source=app&utm_content=about_support_us_link"
              )}>
            <div class="item-media">
              <i class="icon material-icons">favorite</i>
            </div>
            <div class="item-inner">
              <div class="item-title">{tr("about.support_us")}</div>
            </div>
          </a>
        </li>
        <li>
          <a
            href={"#"}
            class="item-link item-content"
            on:click={() =>
              browseURL(
                "https://coherence-app.com/information/how-to-practice/?utm_source=app&utm_content=website_link"
              )}>
            <div class="item-media">
              <img src="/icons/icon.svg" alt="Coherence Logo" />
            </div>
            <div class="item-inner">
              <div class="item-title">{tr("about.how_to_use")}</div>
            </div>
          </a>
        </li>
        <li>
          <a
            href={"#"}
            class="item-link item-content"
            on:click={() =>
              browseURL(
                "https://coherence-app.com/about-coherence/?utm_source=app&utm_content=more_info_link"
              )}>
            <div class="item-media">
              <i class="icon material-icons">help_outline</i>
            </div>
            <div class="item-inner">
              <div class="item-title">{tr("about.more_info")}</div>
            </div>
          </a>
        </li>
        <li>
          <a href="/introduction/" class="item-link item-content">
            <div class="item-media">
              <i class="icon material-icons">support</i>
            </div>
            <div class="item-inner">
              <div class="item-title">{tr("about.show_intro")}</div>
            </div>
          </a>
        </li>
        <li>
          <a
            href={"#"}
            on:click={() => browseURL(FEEDBACK_URL)}
            class="item-link item-content">
            <div class="item-media">
              <i class="icon material-icons">email</i>
            </div>
            <div class="item-inner">
              <div class="item-title">{tr("about.send_feedback")}</div>
            </div>
          </a>
        </li>
        <li>
          <a href="/settings/privacy/" class="item-link item-content">
            <div class="item-media">
              <i class="icon material-icons">privacy_tip</i>
            </div>
            <div class="item-inner">
              <div class="item-title">{tr("privacy.title")}</div>
            </div>
          </a>
        </li>
        <li>
          <a href="/settings/licenses/" class="item-link item-content">
            <div class="item-media">
              <i class="icon material-icons">people</i>
            </div>
            <div class="item-inner">
              <div class="item-title">
                {tr("settings.credits_and_licenses")}
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <Toolbar position="bottom" bgColor="#00000000">
    <span></span>
    <Link on:click={showDebugPage} style="cursor: default;">
      {#await getAppInfo()}
        <span>v...</span>
      {:then appInfo}
        v{appInfo.version}
      {:catch error}
        <span style="color: red">{error.message}</span>
      {/await}
    </Link>
  </Toolbar>
</div>

<script lang="ts">
  import {
    Block,
    BlockTitle,
    Icon,
    List,
    ListItem,
    Navbar,
    Page
  } from "framework7-svelte";
  import {
    getLanguage,
    languages,
    setLanguage,
    translate as trsl
  } from "../js/i18n.ts";

  import { f7, f7ready } from "framework7-svelte";
  import { onMount } from "svelte";
  import { Event, recordEvent } from "../js/analytics.ts";
  import { browseURL, device, shareApp } from "../js/app.ts";
  import { FEEDBACK_URL } from "../js/media.ts";
  import { APP_NAME } from "../js/settings.ts";

  let langSelectEl: ListItem;
  let currentLang = getLanguage();
  let tr = trsl; // store translate fn so it can be refresh on lang chjange for svelte to update ui

  const rateApp = () => {
    f7.dialog
      .create({
        title: tr("common.rate_app"),
        text: tr("settings.rate_app_question"),
        buttons: [
          {
            text: tr("common.cancel")
          },
          {
            text: tr("common.yes"),
            strong: true
          },
          {
            text: tr("common.no")
          }
        ],
        onClick: (_, index) => {
          if (index == 1) {
            // analytics
            recordEvent(Event.Share, {
              content_type: "happy",
              method: "market"
            });

            // user happy, redirect to store for rating
            if (device.android) {
              // browseURL("market://details?id=com.genux.coherence");
              browseURL(
                "https://play.google.com/store/apps/details?id=com.genux.coherence"
              );
            }

            f7.dialog.alert(tr("common.thank_you"));
          } else if (index == 2) {
            // user not happy, send an email instead of rating on the stores
            recordEvent(Event.Share, {
              content_type: "nothappy",
              method: "market"
            });

            browseURL(FEEDBACK_URL);
          }
        }
      })
      .open();
  };

  const onShare = () => {
    shareApp().then(() => f7.dialog.alert(tr("common.thank_you")));
  };

  let count = 0;
  onMount(() => {
    f7ready(() => {
      const smartSelect = langSelectEl.smartSelectInstance();
      smartSelect.on("close", () => {
        const lang = smartSelect.getValue();
        currentLang = lang;
        count = count + 1;
        setLanguage(lang);
        // forece refresh UI for lang to change
        tr = trsl;
      });
    });
  });

  const items = [
    {
      url: "/settings/session/",
      icon: "headphones",
      title: "settings.media"
    },
    {
      url: "/settings/statistics/",
      icon: "bar_chart",
      title: "settings.statistics"
    },
    {
      url: "/settings/notifications/",
      icon: "alarm",
      title: "settings.notifications"
    },
    {
      url: "#",
      onClick: onShare,
      icon: "share",
      title: "common.share"
    }
  ];
</script>

<Page name="settings">
  <Navbar title={APP_NAME} backLink="Back"></Navbar>
  <BlockTitle>{tr("about.slogan")}</BlockTitle>
  <Block>
    <p>{@html tr("about.description")}</p>
  </Block>

  <List>
    <ListItem title={tr("settings.about")} link={"/settings/about/"}>
      <img
        slot="media"
        src="/icons/icon.svg"
        height="32"
        alt="Coherence Logo" />
    </ListItem>
    {#each items as i, index (i.title)}
      <ListItem title={tr(i.title)} link={i.url} onClick={i.onClick}>
        <Icon slot="media" material={i.icon} size="32"></Icon>
      </ListItem>
      <!-- insert the special language item -->
      {#if index == 2}
        <ListItem
          title={tr("settings.language")}
          smartSelect
          bind:this={langSelectEl}>
          <Icon slot="media" material="language" size="32"></Icon>
          <select name="language">
            {#each Object.entries(languages) as [code, name]}
              {#if code == currentLang}
                <option selected value={code}>{name}</option>
              {:else}
                <option value={code}>{name}</option>
              {/if}
            {/each}
          </select>
        </ListItem>
      {/if}
    {/each}

    <!-- on a phone/device allow to rate the app on the market -->
    {#if device.native}
      <ListItem title={tr("common.rate_app")} link={"#"} onClick={rateApp}>
        <Icon slot="media" material="thumb_up" size="32"></Icon>
      </ListItem>
    {/if}
  </List>
</Page>

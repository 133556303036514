<script>
  import {
    AreaChart,
    Block,
    BlockTitle,
    f7,
    Icon,
    Link,
    Navbar,
    Page,
    Tab,
    Tabs,
    Toolbar
  } from "framework7-svelte";
  import { onDestroy, onMount } from "svelte";
  import Ribbon from "../../components/ribbon.svelte";
  import WeekBadge from "../../components/week-badge.svelte";
  import { formatDuration } from "../../js/date.ts";
  import { getLanguage, translate as tr } from "../../js/i18n.ts";
  import {
    getMinutesPracticedOnDay,
    getPracticedWeekDays,
    getSessionsForDay,
    getSessionsForMonth,
    getStats,
    getTotalDaysIncludingToday
  } from "../../js/session.ts";

  export let f7router;

  const stats = getStats();
  const language = getLanguage();
  let curDaySessionsRef = []; // selected day sessions list
  let curDayStr = "";

  const todayDate = new Date();
  const practicedWeekDays = getPracticedWeekDays(stats, todayDate);

  // reactive statements
  const minPracticedTotal = stats.totalMins;
  const minPracticedToday = getMinutesPracticedOnDay(stats, todayDate);
  const totalDays = getTotalDaysIncludingToday(stats);

  const onBack = () => {
    // if there's no back page, go home
    if (!f7router) return;
    // use the home page if there's no back page
    if (f7router.history && f7router.history.length < 2) {
      // couting this page
      f7router.navigate("/", {
        animate: true,
        clearPreviousHistory: true
      });
    } else {
      f7router.back();
    }
  };

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  let calendarInline;

  // create a set of unique days
  const allSessionDates = [];
  const daySet = new Set();

  stats.sessions.forEach((stat) => {
    if (!stat.date) return;
    const dt = new Date(stat.date);
    const dayStr = `${dt.getYear()}-${dt.getMonth()}-${dt.getDate()}`;
    if (daySet.has(dayStr)) return;
    allSessionDates.push(dt);
    daySet.add(dayStr);
  });

  const onTabChange = (el) => {};

  // charts
  let dates = [];
  let minPerMonth = [];

  const axisDateFormat = Intl.DateTimeFormat(language, {
    month: "short",
    year: "numeric"
  });
  const tooltipDateFormat = Intl.DateTimeFormat(language, {
    month: "long",
    year: "numeric"
  });
  // charts

  const updateDaysSessions = (year, month, day) => {
    const daySessStats = getSessionsForDay(stats, year, month, day);
    // update display
    curDaySessionsRef = daySessStats.length > 0 ? daySessStats : [];
    curDayStr = new Date(year, month, day).toLocaleDateString(language);
  };

  const formatThousands = (num) => {
    // TODO: i18n
    if (num >= 1000000) {
      return (num / 1000000).toFixed(2) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(2) + "K";
    } else {
      return num;
    }
  };

  onMount(() => {
    // Inline with custom toolbar
    var monthNames = [...Array(12).keys()].map((key) =>
      new Date(0, key).toLocaleString(language, { month: "long" })
    );
    // Inline with custom toolbar
    calendarInline = f7.calendar.create({
      containerEl: "#calendar-inline-container",
      multiple: true,
      value: allSessionDates,
      disabled: function (date) {
        const daySessStats = getSessionsForDay(
          stats,
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        );
        return daySessStats.length === 0; // has a session
      },
      renderToolbar: function () {
        return (
          '<div class="toolbar calendar-custom-toolbar no-shadow" style="background-color: unset;">' +
          '<div class="toolbar-inner">' +
          '<div class="left">' +
          '<a class="link icon-only"><i class="icon icon-back"></i></a>' +
          "</div>" +
          '<div class="center"></div>' +
          '<div class="right">' +
          '<a class="link icon-only"><i class="icon icon-forward"></i></a>' +
          "</div>" +
          "</div>" +
          "</div>"
        );
      },
      on: {
        dayClick: function (calendar, dayEl, year, month, day) {
          updateDaysSessions(year, month, day);
          // workaround de-selection: refresh with all dates to not show de-selection
          // TODO: could be slow with lots of values
          calendarInline.setValue(allSessionDates);
        },
        init: function (c) {
          f7.$(".calendar-custom-toolbar .center").text(
            monthNames[c.currentMonth] + ", " + c.currentYear
          );
          f7.$(".calendar-custom-toolbar .left .link").on("click", function () {
            calendarInline.prevMonth();
          });
          f7.$(".calendar-custom-toolbar .right .link").on(
            "click",
            function () {
              calendarInline.nextMonth();
            }
          );
          // update table display
          const now = new Date();
          updateDaysSessions(now.getFullYear(), now.getMonth(), now.getDate());
        },
        monthYearChangeStart: function (c) {
          f7.$(".calendar-custom-toolbar .center").text(
            monthNames[c.currentMonth] + ", " + c.currentYear
          );
        }
        // change: function (calendar, value) {
        // },
      }
    });

    // Charts
    // create two arrays one of months dates, one of values (totalmin - currentmonthmins)
    // TODO, start at first date
    for (let i = 0; i < 4; i += 1) {
      let totalM = stats.totalMins; // decrease total minutes
      if (totalM < 0) throw Error("totalM < 0!");

      const curDate = new Date(year, month - i);
      dates = [...dates, curDate];

      const mstats = getSessionsForMonth(stats, curDate);

      console.log("mstats", mstats);
      const monthMins = mstats.reduce((acc, val) => val.elapsed || 0 + acc, 0);
      totalM = totalM - monthMins;

      if (totalM < 0) {
        totalM = 0;
        console.error(Error("totalM < 0!"));
      }

      minPerMonth = [...minPerMonth, totalM];
    }
  });

  onDestroy(() => {
    calendarInline.destroy();
  });
</script>

<Page name="stats" class="page-stats" hideBarsOnScroll>
  <Navbar
    title={tr("settings.statistics")}
    backLink={true}
    on:clickBack={onBack} />

  <Tabs swipeable={false}>
    <Tab id="tab-stats" tabActive>
      <Block>
        <div class="text-align-center top-results">
          <Icon material="emoji_events" size="48px" color="yellow"></Icon>
          <Ribbon text={totalDays} />
          <span class="text-total-days"
            >{`${tr("stats.total")} ${tr("common.day", { count: totalDays })}`}</span>
        </div>
      </Block>

      <p />
      <Block>
        <div class="text-align-center">
          <span style="font-weight: bold;">{tr("stats.week")}</span>
          <WeekBadge checkedDays={practicedWeekDays}></WeekBadge>
        </div>
      </Block>

      <p />
      <Block>
        <div class="grid grid-cols-2 grid-gap">
          <div class="text-align-center">
            <span class="text-large">{stats.currentStreak}</span>
            <br />
            <span class="text-small"
              >{`${tr("stats.days_in_row", { count: stats.currentStreak })}`}</span>
          </div>

          <div class="text-align-center">
            <span class="text-large">{minPracticedToday}</span>
            <br />
            <span class="text-small"
              >{`${tr("common.minute", { count: minPracticedToday })} ${tr("common.today")}`}</span>
          </div>

          <div class="text-align-center">
            <span class="text-large">{formatThousands(stats.totalMins)}</span>
            <br />
            <span class="text-small"
              >{`${tr("common.minute", { count: minPracticedToday })} ${tr("stats.total")}`}</span>
          </div>

          <div class="text-align-center">
            <span class="text-large"
              >{formatThousands(stats.totalBreaths)}</span>
            <br />
            <span class="text-small">{tr("stats.total_breaths")}</span>
          </div>
        </div>
      </Block>
      <Block>
        <p style="margin-top: 2em; font-size: 1.1em;">
          <span class="time-result">
            {@html tr("stats.amazing_results", {
              duration: formatDuration(Math.floor(stats.totalMins * 60 * 1000))
            })}
          </span>
          <span style="color: var(--f7-block-title-text-color);"
            >{@html tr("stats.continue_pep_talk")}</span>
        </p>
        <p>
          <span>
            {@html tr("about.recommendation")}
          </span>
        </p>
      </Block>
    </Tab>
    <Tab id="tab-calendar">
      <BlockTitle>{tr("stats.sessions_practiced_title")}</BlockTitle>
      <Block>
        <div id="calendar-inline-container"></div>
        <div style="margin-left: 8px; font-size: 0.9em">
          🟣 {tr("stats.day_with_coherence")}
        </div>
        <!-- show table only when a day is selected -->
        {#if curDaySessionsRef.length > 0}
          <div class="data-table data-table-init">
            <table>
              <thead>
                <tr>
                  <th class="label-cell"
                    >{curDayStr}: {curDaySessionsRef.length}
                    {tr("common.session", {
                      count: curDaySessionsRef.length
                    })}
                  </th>
                  <th class="numeric-cell"
                    >{tr("common.minute", { count: 2 })}
                  </th>
                </tr>
              </thead>
              <tbody>
                {#each curDaySessionsRef as stat}
                  <tr>
                    <td class="label-cell">
                      {stat.date
                        ? new Date(stat.date).toLocaleTimeString(language)
                        : "Unknown"}
                    </td>
                    <td class="numeric-cell">
                      {stat.elapsed
                        ? (stat.elapsed / 60).toLocaleString(language, {
                            maximumFractionDigits: 1
                          })
                        : "0"}
                    </td>
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        {/if}
      </Block>
    </Tab>

    <Tab id="tab-charts">
      <BlockTitle>{tr("stats.total_minutes_per_month")}</BlockTitle>
      <Block>
        <AreaChart
          tooltip
          axis
          axisLabels={dates}
          formatAxisLabel={(date) => axisDateFormat.format(date)}
          formatTooltipAxisLabel={(date) => tooltipDateFormat.format(date)}
          datasets={[
            {
              label: tr("common.minute", { count: 2 }),
              color: "#9932cc",
              values: minPerMonth
            }
          ]} />
      </Block>
      <Block>{tr("common.click_for_details")}</Block>
    </Tab>
  </Tabs>

  <Toolbar tabbar icons position="bottom">
    <Link
      tabLink="#tab-stats"
      tabLinkActive
      iconMaterial="bar_chart"
      color="grey"
      onClick={onTabChange}
      text={tr("stats.stats")} />
    <Link
      tabLink="#tab-calendar"
      iconMaterial="event"
      color="orange"
      onClick={onTabChange}
      text={tr("stats.calendar")} />
    <Link
      tabLink="#tab-charts"
      iconMaterial="pie_chart"
      color="green"
      onClick={onTabChange}
      text={tr("stats.charts")} />
  </Toolbar>
</Page>

<style>
  .text-total-days {
    font-size: 1em;
    font-weight: bold;
  }

  .text-large {
    font-size: 2em;
    font-weight: bold;
  }

  :global(.stats span.time-result > strong) {
    font-size: 1.1em;
  }

  :global(.toolbar.toolbar-bottom.tabbar) {
    background-color: unset;
  }

  :global(.calendar-day-selected .calendar-day-number) {
    background-color: violet;
  }

  :global(.page-stats .block) {
    margin: 0;
  }
</style>
